import React, { useState } from 'react'
// import {graphql, useStaticQuery} from 'gatsby'
import { Helmet } from 'react-helmet'
// import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import connectorImage from '../resources/images/screenshots/image-contact-form.jpg'
// import {getCookieByName} from '../helpers/getCookieByName'
// import {useForm} from "react-hook-form";
import EnterpriseSupportForm from '../components/forms/EnterpriseForm'
import { navigate } from '@reach/router'

// declare const window: any

interface StateProps {
    enterpriseSupportState: ContactState
}

interface ContactState {
    pardotHandler: string
    headingTitle: string
    displayParagraph: string
    image: string
    messagePlaceholder: string
    contactSource: string
    validateEmailFlag: boolean
}

// const gettingStartedOnAwsMsk: React.FC<StateProps> = () => {
const enterpriseSupport: React.FC<StateProps> = () => {
    //   const data = useStaticQuery(graphql`
    //   query EnterpriseFormSEO {
    //     allContentfulPages(
    //       filter: { pageReference: { pageIdentifier: { eq: "Enterprise_support_form" } } }
    //     ) {
    //       edges {
    //         node {
    //           title {
    //             title
    //           }
    //           keywords {
    //             keywords
    //           }
    //           description {
    //             description
    //           }
    //           oGimage {
    //             fluid(quality: 100) {
    //               src
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // `)

    // const pageSEO = data?.allContentfulPages?.edges[0]?.node

    const [contactState] = useState<ContactState>({
        pardotHandler: 'https://go.pardot.com/l/877242/2024-02-20/syqhq1',
        headingTitle: 'Enterprise Support',
        displayParagraph: "We're here to help",
        image: connectorImage,
        messagePlaceholder: 'Tell us about your use case',
        contactSource: 'NONE',
        validateEmailFlag: false,
    })

    const { pardotHandler, image, messagePlaceholder, contactSource, validateEmailFlag } =
        contactState

    // const form = useForm()

    // useEffect(() => {
    //     var url_string = window.location.href
    //     var url = new URL(url_string)
    //     var source = url.searchParams.get('s')
    //     if (source) {
    //         if (source === 'aiven-discount') {
    //             setContactState({
    //                 ...contactState,
    //                 contactSource: source,
    //                 headingTitle: 'Contact us to trial Aiven + Lenses',
    //                 image: aiven_hero,
    //                 displayParagraph:
    //                     'Plus claim your $350 free Kafka credit - months worth of free fully managed Kafka.',
    //             })
    //         } else if (source === 'quote-developer-team') {
    //             setContactState({
    //                 ...contactState,
    //                 headingTitle: 'Just some quick details...',
    //                 displayParagraph:
    //                     'Someone will be in touch to complete your purchase.',
    //                 contactSource: source,
    //                 pardotHandler: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlc',
    //                 validateEmailFlag: true,
    //             })
    //         } else if (source === 'quote' || source === 'quote-platform-team') {
    //             setContactState({
    //                 ...contactState,
    //                 headingTitle: 'Get a quote',
    //                 displayParagraph:
    //                     'Someone will be in touch to complete your purchase.',
    //                 messagePlaceholder:
    //                     'Tell us about your Kafka environments & use cases',
    //                 contactSource: source,
    //                 pardotHandler: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlc',
    //                 validateEmailFlag: true,
    //             })
    //         }
    //         let emailStorage = getEmailLocalStorage()
    //         if (emailStorage) {
    //             document.querySelector<HTMLInputElement>('#email').value = emailStorage
    //         }
    //     }
    // }, [])

    const handleFormSubmit = (connector) => {
        navigate(`/enterprise-support-ok/?connector=${connector}`)
        // document.querySelector<HTMLInputElement>('#splunk_id').value =
        //     getCookieByName('mintjs%3Auuid')
        // document.querySelector<HTMLInputElement>('#timestamp').value = new Date().toLocaleString()
        // document.querySelector<HTMLInputElement>('#zoominfo-default-form').disabled = true
    }

    return (
        <Layout onlyLogo={true}>
            {/*<HeaderSEO pageSEO={pageSEO}/>*/}
            <Helmet>
                <link rel='canonical' href='https://lenses.io/enterprise-support/' />
            </Helmet>
            <section>
                <div className='container-1 hero-margin mb-4 mobile-padding-reset'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-lg-5 col-10 col-md-8 pt-5 px-2'>
                            <h1>Kafka Connectors - Enterprise Support</h1>
                            <img
                                style={{ maxHeight: '250px' }}
                                className='img-fluid mx-auto d-block pt-5 mobile-padding-reset'
                                src={image}
                                alt='Contact Lenses team'
                            />

                            <div
                                className='hero-description-dark pt-5 mobile-padding-reset'
                                style={{ fontSize: '16px' }}>
                                Get in touch with our team to see the full capability of our Apache
                                2 licensed connectors paired with enterprise-grade support:
                                <ul style={{ fontSize: '16px', marginTop: '15px' }}>
                                    <li>Incident response for security vulnerabilities</li>
                                    <li>24/7 support with SLAs</li>
                                    <li>Priority on feature requests</li>
                                    <li>Direct Slack communication with the Lenses team</li>
                                    <li>
                                        Enhanced Developer Experience when deployed through Lenses
                                    </li>
                                </ul>
                            </div>
                            <p className='hero-description-dark mobile-padding-reset'>
                                <span style={{ fontSize: '18px' }}>
                                    Contact us{' '}
                                    <a href='mailto:info@lenses.io' id='contactus-link-salesemail'>
                                        info@lenses.io
                                    </a>
                                </span>{' '}
                                {/*<span style={{ fontSize: '18px' }}>*/}
                                {/*    Contact Sales at{' '}*/}
                                {/*    <a href='mailto:sales@lenses.io' id='contactus-link-salesemail'>*/}
                                {/*        sales@lenses.io*/}
                                {/*    </a>*/}
                                {/*</span>{' '}*/}
                                {/*<br />*/}
                                {/*<span style={{ fontSize: '18px' }}>*/}
                                {/*    Contact Support at{' '}*/}
                                {/*    <a href='mailto:support@lenses.io' id='contactus-link-support'>*/}
                                {/*        support@lenses.io*/}
                                {/*    </a>*/}
                                {/*</span>*/}
                                {/*<br />*/}
                                {/*<span style={{ fontSize: '18px' }}>*/}
                                {/*    Other Enquiries at{' '}*/}
                                {/*    <a href='mailto:info@lenses.io' id='contactus-link-infoemail'>*/}
                                {/*        info@lenses.io*/}
                                {/*    </a>*/}
                                {/*</span>*/}
                            </p>
                        </div>

                        <div
                            id='request'
                            className='px-0 col-lg-6 offset-lg-1 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom'>
                            <div className='footer-color py-5 px-2 rounded'>
                                <div className='d-flex flex-wrap'>
                                    <div className='col-md-12 mobile-padding-reset'>
                                        {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                                        <EnterpriseSupportForm
                                            formId='enterprise-form'
                                            onSubmit={handleFormSubmit}
                                            action={pardotHandler}
                                            inputId='formsubmit'
                                            inputValue='Send Now'
                                            firstName={'required'}
                                            lastName={'required'}
                                            email={'required'}
                                            company={'notRequired'}
                                            message={'notRequired'}
                                            messageValue={messagePlaceholder}
                                            type='contactUs'
                                            contactSource={contactSource}
                                            validateEmailFlag={validateEmailFlag}
                                            connectorsField={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default enterpriseSupport
