import React, { useState } from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
// import ZoominfoFields from '../../components/partials/Zoominfo-fields'

// import { getClientId } from '../../helpers/getClientId'
// import callZoominfo from '../../helpers/zoomInfo'
// import { getCountry } from '../../helpers/getCountry'
// import axios from 'axios'
import { useLocation } from '@reach/router'
import * as CompanyEmailValidator from 'company-email-validator'

const connectors = [
    { id: 1, option: 'Choose a connector', param: undefined },
    { id: 2, option: 'Amazon S3', param: 'aws-s3' },
    { id: 3, option: 'Azure Event Hubs', param: 'azure-event-hubs' },
    { id: 4, option: 'Google Cloud Storage', param: 'google-cloud-storage' },
    { id: 5, option: 'Google Cloud Pub/Sub', param: 'google-cloud-pub-sub' },
    { id: 6, option: 'Azure Data Lake', param: 'azure-data-lake' },
    { id: 7, option: 'FTP', param: 'ftp' },
    { id: 8, option: 'InfluxDB', param: 'influxdb' },
    { id: 9, option: 'HTTP', param: 'http' },
    { id: 10, option: 'JMS', param: 'jms' },
    { id: 11, option: 'Cassandra', param: 'cassandra' },
    { id: 12, option: 'MongoDB', param: 'mongodb' },
    { id: 13, option: 'Redis', param: 'redis' },
    { id: 14, option: 'MQTT', param: 'mqtt' },
    { id: 15, option: 'Azure CosmosDB', param: 'azure-cosmosdb' },
    { id: 16, option: 'Azure Service Bus', param: 'azure-service-bus' },
    { id: 17, option: 'Elasticsearch', param: 'elasticsearch' },
    { id: 18, option: 'Celonis', param: 'celonis' },
]

const EnterpriseSupportForm = ({
    // formId,
    message,
    messageValue,
    firstName,
    lastName,
    inputId,
    inputValue,
    // demoRequest,
    connectorsField,
    btnText,
}) => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const connectorParam = queryParams.get('connector')

    const [selectedOption, setSelectedOption] = useState(
        connectors.find((connector) => connector?.param === connectorParam)?.option ??
            connectors[0].option,
    ) // Set the initial selected option here

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value) // Update the selected option when the user changes the selection
    }

    // const getConnectorParamCodeBaseOnSelectedOption = () => {
    //     return connectors.find((connector) => connector.option === selectedOption)?.param
    // }

    // useEffect(() => {
    //     // Assisting Scripts
    //     try {
    //         // Zoominfo Snippet - Binding by formID
    //         if (formId === 'zoominfo-form-with-phone') {
    //             callZoominfo('pJHlgY8C79g4kici9R8C')
    //         } else if (formId === 'zoominfo-default-form') {
    //             callZoominfo('qeaakHWCTtKYuT98MCW4')
    //         }
    //         // Getting clientId
    //         const interval = setInterval(function () {
    //             if (getClientId()) {
    //                 let gaClientId = getClientId()
    //                 document.querySelector('#ga_client_id').value = gaClientId
    //                 clearInterval(interval)
    //             }
    //         }, 1000)
    //         // Getting country name for routing
    //         getCountry()
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }, [])

    // useEffect(() => {
    //     // Add reCaptcha
    //     const script = document.createElement('script')
    //     script.src =
    //         'https://www.google.com/recaptcha/api.js?render=6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
    //     script.addEventListener('load', handleLoaded)
    //     document.body.appendChild(script)
    //
    //     return () => removeEventListener('load', handleLoaded)
    // }, [])
    //
    const [disabledButton] = useState(false)
    //
    // // Extra control to prevent enabling button without having triggered recaptcha
    // // Needed to prevent non-professional email submission
    // let [passedRecaptcha, setPassedRecaptcha] = useState(false)

    const validateEmail = () => {
        const email = document.querySelector('#email')
        if (!CompanyEmailValidator.isCompanyEmail(email.value)) {
            email.classList.add('is-invalid')
            document.querySelector('#formsubmit').disabled = true
        } else {
            email.classList.remove('is-invalid')
            document.querySelector('#formsubmit').disabled = false
        }
    }

    // const reCAPTCHATriggered = () => {
    //     // On Business Emails
    //     if (validateEmailFlag) {
    //         try {
    //             document.querySelector('#formsubmit').disabled = false
    //             setPassedRecaptcha(true)
    //             validateEmail()
    //         } catch (error) {
    //             console.log(error)
    //         }
    //         // Default Behaviour
    //     } else {
    //         document.querySelector('#formsubmit').disabled = false
    //     }
    // }

    // const handleLoaded = () => {
    //     window.grecaptcha.ready(() => {
    //         window.grecaptcha
    //             .execute('6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF', {
    //                 action: 'verify',
    //             })
    //             .then((token) => {
    //                 try {
    //                     axios
    //                         .post(
    //                             `https://m03u65lddf.execute-api.eu-west-2.amazonaws.com/reCaptchaWebsiteScore?token=${token}`,
    //                         )
    //                         .then(function (res) {
    //                             const data = res.data
    //                             let score = data.response.score
    //                             if (score > 0.8) {
    //                                 // pass
    //                                 setDisabledButton(false)
    //                                 setPassedRecaptcha(true)
    //                             }
    //                         })
    //                 } catch (error) {
    //                     console.log(error)
    //                 }
    //             })
    //     })
    // }

    return (
        <form
            id='enterprise-form'
            action='https://go.pardot.com/l/877242/2024-02-25/syskrb'
            acceptCharset='UTF-8'
            method='POST'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {firstName && (
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        style={{ marginRight: '20px' }}
                        type='text'
                        required
                        id='first_name'
                        maxLength={80}
                        name='first_name'
                        placeholder='First Name*'
                    />
                )}
                {lastName && (
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        type='text'
                        required
                        id='last_name'
                        maxLength={80}
                        name='last_name'
                        placeholder='Last Name*'
                    />
                )}
            </div>
            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='email'
                required
                id='email'
                maxLength={100}
                name='email'
                placeholder='Email*'
                onChange={validateEmail}
            />
            {validateEmail && (
                <div className='invalid-feedback ml-2'>Please provide your professional email</div>
            )}

            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='text'
                maxLength={100}
                id='company'
                name='company'
                placeholder='Company'
            />

            {connectorsField && (
                <select
                    className='my-1 bg-white countries-dropdown'
                    name='connector'
                    id='connector'
                    value={selectedOption}
                    onChange={handleSelectChange}
                    style={{ width: '100%' }}>
                    {connectors.map((connector) => (
                        <option key={connector.id} value={connector.option}>
                            {connector.option}
                        </option>
                    ))}
                </select>
            )}

            {message && message === 'required' ? (
                <textarea
                    className='footer-color w-100 pl-2 input-form-border-textarea py-3 placeholder-opacity my-2'
                    name={'message'}
                    id='textarea'
                    maxLength={2000}
                    placeholder={messageValue + '*'}
                    required></textarea>
            ) : message === 'notRequired' ? (
                <textarea
                    className='footer-color w-100 pl-2 input-form-border-textarea py-3 placeholder-opacity my-2'
                    id='textarea'
                    name={'message'}
                    maxLength={2000}
                    placeholder={messageValue}></textarea>
            ) : (
                ''
            )}

            {/*<input name='Timestamp' id='timestamp' className='d-none' />*/}
            {/*<input name='License' id='license' className='d-none' />*/}
            {/*<input name='downloadURL' id='downloadURL' className='d-none' />*/}
            {/*<input name='Expiration Date' id='expiration_date' className='d-none' />*/}

            {/*<input name='GA Client ID' type='hidden' className='d-none' id='ga_client_id' />*/}

            {/*<input type='hidden' name='Splunk ID' id='splunk_id' className='d-none' />*/}

            {/*<input type='text' className='d-none' id='country_name' name='Country Name' />*/}
            <div className='m-2 mb-4'>
                <label className='d-none' htmlFor='Terms Of Use'>
                    {' '}
                    Terms of use and Privacy Policy
                </label>
                <input type='checkbox' id='terms_of_use' name='Terms Of Use' required />{' '}
                <span className='pl-1'>
                    By submitting the form, you agree to our{' '}
                    <a className='link-text' href='/legals/terms-and-conditions/'>
                        Terms of Use{' '}
                    </a>
                    ,{' '}
                    <a className='link-text' href='/legals/policy/'>
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a className='link-text' href='/legals/sela/'>
                        SELA
                    </a>
                </span>
            </div>
            <div
                className='g-recaptcha'
                data-sitekey='6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
                data-size='invisible'></div>
            <div>
                <input
                    className={`${
                        disabledButton && 'mt-5'
                    } bg-red text-white  br-21 monitoring-guide-cta-padding border-0`}
                    id={inputId}
                    type='submit'
                    value={btnText ?? inputValue}
                    disabled={disabledButton}></input>
            </div>
        </form>
    )
}

export default EnterpriseSupportForm

EnterpriseSupportForm.propTypes = {
    formId: PropTypes.string,
    action: PropTypes.string,
    onSubmit: PropTypes.func,
    validateEmailFlag: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    message: PropTypes.string,
    messageValue: PropTypes.string,
    inputId: PropTypes.string,
    inputValue: PropTypes.string,
    trial_source: PropTypes.string,
    type: PropTypes.string,
    contactSource: PropTypes.string,
    demoRequest: PropTypes.bool,
    connectorsField: PropTypes.bool,
    btnText: PropTypes.string,
}
